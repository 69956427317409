import '@fancyapps/fancybox';
import './plugins/inView';
import Swiper, {
    Navigation, Pagination, Controller, EffectFade
} from 'swiper';
import Plyr from 'plyr';

import mapBox from './inc/hmMaps';
// import smoothScroll from './inc/smoothScroll';
import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';

Swiper.use([Navigation, Pagination, Controller, EffectFade]);

const LazyLoad = require('vanilla-lazyload');

const Masonry = require('masonry-layout');
const InfiniteScroll = require('infinite-scroll');
const imagesLoaded = require('imagesloaded');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    // Smooth Mousewheel Scrolling for Chrome & Safari
    // smoothScroll();

    if ($('.az-links').length) {
        $('.az-links li.has-posts a').on('click', (e) => {
            e.preventDefault();
            const target = $(e.currentTarget);
            $('html, body').animate({
                scrollTop: $(target.attr('href')).offset().top - 170
            }, 500);
        });
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    /**** Map Box ***/
    if ($('#map').length) {
        mapBox();
    }

    /**** PLYR ***/
    const plyr = Array.from(document.querySelectorAll('.plyr')).map((p) => new Plyr(p, {
        controls:
        ['play-large', 'play', 'progress', 'current-time', 'mute',
            'volume', 'captions', 'pip', 'airplay', 'fullscreen']
    }));

    for (let plyri = 0; plyri < plyr.length; plyri++) {
        plyr[plyri].on('ready', (event) => {
            $(event.target).addClass('notClicked vis');
            $(event.target).attr('data-index', plyri);
        });
        plyr[plyri].on('playing', (event) => {
            $(event.target).removeClass('notClicked');
        });
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();
}

$(window).on('load', () => {
    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    new Swiper('.swiper-header', {
        // Optional parameters
        loop: true,
        navigation: {
            nextEl: '#bigHeaderWrapper .swiper-button-next',
            prevEl: '#bigHeaderWrapper .swiper-button-prev'
        },
        pagination: {
            el: '#bigHeaderWrapper .swiper-pagination',
            clickable: true
        }
    });

    new Swiper('.swiper-members', {
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '#memberBtns .swiper-button-next',
            prevEl: '#memberBtns .swiper-button-prev'
        },
        pagination: {
            el: '#memberBtns .swiper-pagination',
            clickable: true
        }
    });

    $('.swiperImages').each((i, el) => {
        const slider = $(el).attr('id');
        const wrapID = $(el).parent().attr('id');

        const imageSlider = new Swiper(`#${slider}`, {
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: `#${wrapID} .swiper-button-next`,
                prevEl: `#${wrapID} .swiper-button-prev`
            },
            pagination: {
                el: `#${wrapID} .swiper-pagination`,
                clickable: true
            }
        });

        const descSlider = new Swiper(`#${wrapID} .descSlider`, {
            loop: true,
            autoHeight: true,
            effect: 'fade',
        });

        imageSlider.controller.control = descSlider;
    });

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.fadeIn').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).not('.vis').addClass('vis');
        }
    });

    $('.fadeInRow').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).find('> div, > li, > p').not('.vis').each((i, el) => {
                setTimeout(() => {
                    $(el).addClass('vis');
                }, 150 * i);
            });
        }
    });

    masonryUpdate();
    infinityUpdate();

    if ($('#az-slider').length) {
        $('.masonry').each((i, el) => {
            const masonry = $(el).attr('id');
            const items = $(el).find('.item').length;

            if (items > 1) {
                new Masonry(`#${masonry}`, {
                    itemSelector: '.item',
                    columnWidth: '.item',
                    percentPosition: true,
                });
            }
        });
    }
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

function masonryUpdate() {
    if ($('.masonryWrap').length) {
        const msnry = new Masonry('.masonryWrap', {
            itemSelector: '.item',
            columnWidth: '.item',
            percentPosition: true,
        });

        if ($('.infinityWrap').length && $('.next').length) {
            // make imagesLoaded available for InfiniteScroll
            InfiniteScroll.imagesLoaded = imagesLoaded;

            const $container = new InfiniteScroll('.masonryWrap', {
                append: '.item',
                path: '.next',
                outlayer: msnry,
                loadOnScroll: false,
                history: false
            });

            const $viewMoreButton = $('.loadMore');

            $viewMoreButton.on('click', () => {
                $container.loadNextPage();
                $container.options.loadOnScroll = true;
                $viewMoreButton.hide();
            });
        }
    }
}

function infinityUpdate() {
    if ($('.infinityWrap:not(.masonryWrap)').length && $('.next').length) {
        // make imagesLoaded available for InfiniteScroll
        InfiniteScroll.imagesLoaded = imagesLoaded;

        const $container = new InfiniteScroll('.infinityWrap', {
            append: '.item',
            path: '.next',
            // outlayer: msnry,
            loadOnScroll: false,
            history: false
        });

        const $viewMoreButton = $('.loadMore');

        $viewMoreButton.on('click', () => {
            $container.loadNextPage();
            $container.options.loadOnScroll = true;
            $viewMoreButton.hide();
        });
    }
}
